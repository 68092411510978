import * as React from "react";

function SvgWolf(props) {
  return (
    <svg viewBox="0 0 339 350" {...props}>
      <g fill="#000" fillRule="evenodd">
        <path d="M85.362.22l61.633 67.658h44.194L252.761.22l42.113 111.91-5.97 5.571 49.338 116.504-88.006 49.688-10.664 28.446-37.625 23.256 29.743-108.59 11.403 28.589 44.61-24.989-16.419-18.723 22.388-13.287c-12.004-29.965-19.467-47.899-22.388-53.801-4.382-8.853-7.129-11.837-13.496-18.699a397.276 397.276 0 00-12.358-12.765l15.712-18.534-16.914-51.375-28.689 32.021-1.201 18.438c-3.32.678-6.393 1.784-9.216 3.316-4.235 2.299-3.71 1.683-6.489 5.86a26.295 26.295 0 00-3.84 9.074l-25.619 116.755-25.288-116.755c-.981-3.31-2.288-6.336-3.922-9.074-2.45-4.107-2.227-3.466-6.418-5.86-2.794-1.596-6.41-2.701-10.847-3.316l-1.201-18.438L94.42 43.422 76.73 95.997l16.892 18.534c-5.808 4.245-11.038 8.9-15.69 13.965a96.437 96.437 0 00-12.108 16.299l-22.48 53.801 23.682 13.287-17.249 18.723 44.643 24.989 11.736-27.389 29.792 107.39-38.428-22.056-9.664-29.646-87.65-48.488 49.339-118.904-6.2-4.37L85.362.22z" />
        <path d="M90.284 178.71l44.536 16.346 8.313 39.163-13.025-15.91-28.356-9.887zM247.834 178.71l-44.536 16.346-8.313 39.163 13.024-15.91 28.357-9.887zM141.47 315.32h5.407l10.682 13.837h22.46l10.884-13.837h6.74l-9.728 27.026-18.567 7.542-19.003-7.542z" />
      </g>
    </svg>
  );
}

export default SvgWolf;
